import React, {FC} from 'react'
import {PdfView} from './PdfView'
const PdfViewPageWrapper: FC = () => {
  return (
    <>
      <PdfView />
    </>
  )
}

export default PdfViewPageWrapper